import { ToastContainer } from 'react-toastify';
import { Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useStore } from '../stores/store';
import { observer } from 'mobx-react-lite';
import CenteredCard from './CenteredCard';

function App() {
  const {commonStore, userStore} = useStore();

  useEffect(() => {
    if (commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded()
    }
  }, [commonStore, userStore]);

  if (!commonStore.appLoaded) return <CenteredCard>Yükleniyor...</CenteredCard>
  
  return (
    <>
      <ToastContainer position="bottom-right" hideProgressBar theme="colored" />
      <Outlet />
    </>
  )
}

export default observer(App);