import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

interface NavItemProps {
  title: String;
  children: React.ReactNode;
  open?: boolean;
  onClick: () =>void;
}

export default function NavItem({ title, children, open, onClick }: NavItemProps) {
  return (
    <ListItem disablePadding sx={{ display: "block" }} onClick={onClick}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {children}
        </ListItemIcon>
        <ListItemText primary={title} sx={{ opacity: open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}