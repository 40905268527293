import { useEffect, useState } from 'react';
import { env } from '../utils/env';
import { Modal, Paper, styled } from '@mui/material';
import Grid from '@mui/material/Grid2';
import agent from '../api/agent';
import { GalleryPhoto } from '../models/galleryPhoto';

interface ImagePickerProps {
  open: boolean;
  onClose: () => void;
  onSelect: (url: string) => void;
}

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#f6f6f6",
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ImagePickerModal({ open, onClose, onSelect }: ImagePickerProps) {
  const [photos, setPhotos] = useState<GalleryPhoto[]>([]);

  useEffect(() => {
    if (open) {
      fetchPhotos();
    }
  }, [open]);

  const fetchPhotos = async () => {
    const response = await agent.GalleryPhotos.list();
    setPhotos(response);
  };

  return (
    <Modal open={open} onClose={onClose} className="modal gallery-modal">
      <div className="modal-content">
        <Grid container spacing={2}>
          {photos.map(photo => (
            <Grid size={2} key={photo.id}>
              <Item className="photo-item">
              <img
                src={env + (photo.photoThumb || photo.photoUrl)}
                alt=""
                onClick={() => {
                  onSelect(photo.photoUrl);
                  onClose();
                }}
                style={{ cursor: 'pointer' }}
              />
              </Item>
            </Grid>
          ))}
        </Grid>
      </div>
    </Modal>
  );
}