import { createContext, useContext } from "react";
import userStore from "./userStore";
import commonStore from "./commonStore";

interface Store {
  userStore: userStore;
  commonStore: commonStore;
}

export const store: Store = {
  userStore: new userStore(),
  commonStore: new commonStore(),
}

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}