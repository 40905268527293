import { useEffect, useState } from "react";
import { Draw } from "../models/draw";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import agent from "../api/agent";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Button, Chip, IconButton } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AdminLayout from "../layout/AdminLayout";

export default function ListDraws() {
  const [draws, setDraws] = useState<Draw[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchDraws = async () => {
    setLoading(true);
    try {
      const response = await agent.Draws.list();
      const fetchedDraws = response;
      setDraws(fetchedDraws);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Çekilişler getirilemedi.');
    }
  };

  useEffect(() => {
    fetchDraws();
  }, []);

  useEffect(() => {
    if (location.state?.reload) {
      fetchDraws();
    }
  }, [location.state]);

  const moveRow = async (id: string, direction: "up" | "down") => {
    try {
      const response = await agent.Draws.listOrder(id, direction);
      const index = draws.findIndex((draw) => draw.id === id);
      if (index === -1) return;

      const newDraws = [...draws];
      const [movedDraw] = newDraws.splice(index, 1);

      if (direction === "up" && index > 0) {
        newDraws.splice(index - 1, 0, movedDraw);
      } else if (direction === "down" && index < newDraws.length) {
        newDraws.splice(index + 1, 0, movedDraw);
      }

      // Update ListOrder
      const updatedDraws = newDraws.map((draw, i) => ({
        ...draw,
        listOrder: i + 1,
      }));

      setDraws(updatedDraws);

      const reorderedDraws = [
        {
          id: movedDraw.id,
          listOrder: updatedDraws.find(c => c.id === movedDraw.id)!.listOrder
        },
        {
          id:
            direction === "up"
              ? newDraws[index].id
              : newDraws[index + 1]?.id,
          listOrder:
            direction === "up"
              ? movedDraw.listOrder
              : newDraws[index + 1]?.listOrder
        },
      ];
    } catch (error) {
      toast.error('Sıralama başarısız!');
    }
  };

  const handleDuplicateDraw = async (id: string) => {
    const {value: drawTitle} = await Swal.fire({
      title: 'Çekilişi klonlamak istediğinizden emin misiniz?',
      text: 'Klonlama işlemi için yeni çekiliş ismi giriniz',
      icon: 'warning',
      input: "text",
      inputLabel: "Yeni Çekiliş İsmi",
      showCancelButton: true,
      confirmButtonText: 'Klonla',
      cancelButtonText: 'İptal',
    });
    
    if (drawTitle) {
      try {
        const response = await agent.Draws.details(id);
        const drawData: any = response;
        drawData.prizes.sort(
          (a: any, b: any) => a.listOrder - b.listOrder
        );
        const newDrawId = uuid();
        const newDrawDesignId = uuid();
        const newDrawData = {
          ...drawData,
          id: newDrawId,
          title: drawTitle,
          drawDesign: {
            ...drawData.drawDesign,
            id: newDrawDesignId,
            drawId: newDrawId,
          },
          prizes: drawData.prizes.map((prize: any, index: any) => ({
            ...prize,
            id: uuid(),
            drawId: newDrawId,
          })),
        }
        try {
          await agent.Draws.create(newDrawData);
          const refreshDraws = await agent.Draws.list();
          setDraws(refreshDraws);
          toast.success('Çekiliş başarıyla klonlandı: ' + drawTitle);
        } catch (error) {
          toast.error('Çekiliş klonlanırken bir hata oluştu.');
        }
      } catch (error) {
        toast.error('Çekiliş bilgileri okunurken bir hata oluştu.');
      }
    } else {
      toast.error('Klonlamak için yeni çekiliş ismi girmelisiniz.');
    }
  };

  const handleDeleteDraw = (id: string) => {
    Swal.fire({
        title: 'Silmek istediğinizden emin misiniz?',
        text: 'Silme işlemini geri alamazsınız!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tamam',
        cancelButtonText: 'İptal',
    }).then((result) => {
        if (result.isConfirmed) {
            agent.Draws.delete(id).then(() => {
                setDraws([...draws.filter(x => x.id !== id)]);
                toast.success('Çekiliş silindi!');
                setDraws([...draws.filter(x => x.id !== id)])
            }).catch((error) => {
                toast.error('Çekiliş silinirken bir hata oluştu.');
            });
        }
    });
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "listOrder", headerName: "Sıra", width: 70 },
    { field: "title", headerName: "Çekiliş Adı", width: 160 },
    {
      field: "startDate",
      headerName: "Başlangıç",
      description: "Çekilişin başlangıç tarihi.",
      width: 180,
      renderCell: (params: any) => dayjs(params.value as string).format("DD.MM.YYYY HH:mm"),
    },
    {
      field: "endDate",
      headerName: "Bitiş",
      description: "Çekilişin bitiş tarihi.",
      width: 180,
      renderCell: (params: any) => dayjs(params.value as string).format("DD.MM.YYYY HH:mm"),
    },
    {
      field: "isActive",
      headerName: "Yayında Mı?",
      width: 140,
      renderCell: (params) => (
        <Chip
          label={params.value ? "Yayında" : "Yayında değil"}
          color={params.value ? "success" : "error"}
        />
      ),
    },
    {
      field: "order",
      headerName: "Sıralama",
      width: 120,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => moveRow(params.row.id, "up")}
            disabled={params.row.listOrder === 1}
          >
            <ArrowUpwardIcon />
          </IconButton>
          <IconButton
            onClick={() => moveRow(params.row.id, "down")}
            disabled={params.row.listOrder === draws.length}
          >
            <ArrowDownwardIcon />
          </IconButton>
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Eylemler",
      width: 360,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => navigate(`/draws/${params.row.id}`)}
            style={{marginRight: "15px"}}
          >
            Düzenle
          </Button>
          <Button
            variant="contained"
            size="small"
            color="warning"
            onClick={() => window.open(`/game/${params.row.id}`, '_blank')}
            style={{marginRight: "15px"}}
          >
            Oyun
          </Button>
          <Button
            variant="contained"
            size="small"
            color="success"
            onClick={() => handleDuplicateDraw(params.row.id)}
            style={{marginRight: "15px"}}
          >
            Klonla
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => handleDeleteDraw(params.row.id)}
          >
            Sil
          </Button>
      </>
      ),
    },
  ];

  return (
    <>
      <AdminLayout title="Çekilişler">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/draws/new")}
          sx={{ mb: 2 }}
        >
          Çekiliş Ekle
        </Button>
        <DataGrid
          rows={draws}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 50]}
          checkboxSelection
        />
      </AdminLayout>
    </>
  );
}