import { Card, CardContent } from "@mui/material";

interface CenteredCardProps {
  children?: React.ReactNode;
  maxWidth?: string;
}

export default function CenteredCard({ children, maxWidth= "auto" }: CenteredCardProps) {
  return (
    <div className="full-screen-centered">
      <Card sx={{minWidth: '40%', maxWidth: maxWidth}}>
        <CardContent>{children}</CardContent>
      </Card>
    </div>
  );
}
