import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import agent from "../api/agent";
import { initializeDrawData, initializeNewDraw } from "../utils/initializeDraw";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/tr";
import Swal from "sweetalert2";
import AdminLayout from "../layout/AdminLayout";
import { Alert, Button, Card, CardContent, Divider, IconButton, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import PeopleIcon from '@mui/icons-material/People';
import DeleteIcon from "@mui/icons-material/Delete";
import AdminField from "../layout/AdminField";
import ImagePickerModal from "../layout/ImagePickerModal";

export default function DrawDetails() {
  const { id } = useParams<{ id: string }>();
  const [draw, setDraw] = useState<any | null>(null);
  const [titleInput, setTitleInput] = useState<string>("");
  const [tabValue, setTabValue] = useState(0);
  const [errors, setErrors] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      agent.Draws.details(id).then((response) => {
        const drawData: any = response;
        drawData.prizes.sort(
          (a: any, b: any) => a.listOrder - b.listOrder
        );
        setDraw(initializeDrawData(drawData));
        setTitleInput(drawData.title);
      });
    } else {
      const newDraw = initializeNewDraw();
      newDraw.prizes.forEach((prize, index) => {
        prize.listOrder = index + 1;
      });
      setDraw(newDraw);
      setTitleInput(newDraw.title);
    }
  }, [id]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handlePrizeAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPrizeAmount = parseInt(event.target.value);
    const currentPrizeAmount = draw?.prizes.length || 5;

    if (newPrizeAmount > currentPrizeAmount) {
      const newPrizes = Array(newPrizeAmount - currentPrizeAmount)
        .fill(null)
        .map((_, index) => ({
          id: uuid(),
          prizeValue: "",
          prizeImage: "",
          platformImage: "",
          listOrder: currentPrizeAmount + index + 1,
          drawId: draw?.Id || null,
        }));
      setDraw({
        ...draw!,
        drawCount: newPrizeAmount,
        prizes: [...draw!.prizes, ...newPrizes],
      });
    } else {
      setDraw({
        ...draw!,
        drawCount: newPrizeAmount,
        prizes: draw!.prizes.slice(0, newPrizeAmount),
      });
    }
  };

  const handleScreenTypeChange = (value: number) => {
    const updatedDraw = { ...draw };
  
    if (value === 2) { // "Üç Platformlu"
      updatedDraw.drawCount = 3;
      updatedDraw.prizes = updatedDraw.prizes.slice(0, 3);
      handlePrizeAmountChange({
        target: { value: "3" },
      } as React.ChangeEvent<HTMLInputElement>);
    } else if (value === 3) { // "Desenli Platformlu"
      if (updatedDraw.drawCount > 8) {
        updatedDraw.drawCount = 8;
        updatedDraw.prizes = updatedDraw.prizes.slice(0, 8);
        handlePrizeAmountChange({
          target: { value: "8" },
        } as React.ChangeEvent<HTMLInputElement>);
      }
    }
  
    setDraw({
      ...updatedDraw,
      drawDesign: { ...updatedDraw.drawDesign, screenType: value },
    });
  };  

  const handlePrizeChange = (
    index: number,
    field: string,
    value: string | boolean | number | null
  ) => {
    if (!draw) return;

    const newPrizes = [...draw.prizes];
    newPrizes[index] = { ...newPrizes[index], [field]: value };
    setDraw({
      ...draw,
      prizes: newPrizes,
    });
  };

  const handleFieldChange = (field: string, value: any) => {
    setDraw({ ...draw, [field]: value });
  };

  const handleDrawDesignChange = (field: string, value: any) => {
    if (!draw) return;

    setDraw({
      ...draw,
      drawDesign: { ...draw.drawDesign, [field]: value },
    });
  };

  const validateForm = () => {
    let tempErrors: any = {};

    if (!titleInput) tempErrors.titleInput = "Çekiliş Başlığı zorunludur";
    if (draw?.prizes.some((prize: any) => !prize.prizeValue)) {
      tempErrors.prizes = "Ödül değeri zorunludur.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    const drawId = draw?.id || uuid();
    const drawDesignId = draw?.drawDesign.id || uuid();

    const drawData = {
      ...draw,
      id: drawId,
      title: titleInput,
      startDate: (draw!.startDate as Dayjs).toISOString(),
      endDate: (draw!.endDate as Dayjs).toISOString(),
      drawDesign: {
        ...draw!.drawDesign,
        id: drawDesignId,        
        drawId: drawId,
      },
      prizes: draw!.prizes.map((prize: any, index: any) => ({
        ...prize,
        id: prize.id || uuid(),
        drawId: drawId,
        listOrder: index + 1,
      }))
    };

    try {
      if (id) {
        await agent.Draws.update(drawData);
      } else {
        await agent.Draws.create(drawData);
      } 
      navigate("/draws", { state: { reload: true } });     
    } catch (error: any) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
      } else {
        console.error("Çekiliş düzenlenirken bir hata oluştu:", error.message);
      }
    }
  };

  const openImagePicker = (field: string) => {
    setActiveField(field);
    setOpenModal(true);
  };

  const handleImageSelect = (url: string) => {
    if (!draw || !activeField) return;

    if (activeField.startsWith("prize")) {
      const [_, indexStr, prizeField] = activeField.split("-");
      const index = parseInt(indexStr, 10);
      handlePrizeChange(index, prizeField, url);
    } else {
      handleDrawDesignChange(activeField, url);
    }

    setOpenModal(false);
  };

  const movePrize = (index: number, direction: "up" | "down") => {
    if (!draw) return;

    const newPrizes = [...draw.prizes];
    const prizeToMove = newPrizes[index];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Swap prizes
    newPrizes[index] = newPrizes[targetIndex];
    newPrizes[targetIndex] = prizeToMove;

    // Update listOrder
    newPrizes[index].listOrder = index + 1;
    newPrizes[targetIndex].listOrder = targetIndex + 1;

    setDraw({
      ...draw,
      prizes: newPrizes,
    });
  };

  const handleDeleteGameData = () => {
    Swal.fire({
      title: "Silmek istediğinizden emin misiniz?",
      text: "Bu çekiliş için kaydedilmiş tüm çekiliş ve kazanan katılımcı verileri silinecektir. Silme işlemini geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Tamam",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        agent.Games.deleteAll(id)
          .then(() => {
            toast.success("Tüm çekiliş ve kazanan katılımcı verileri silindi!");
          })
          .catch((error) => {
            toast.error("Veriler silinirken bir hata oluştu.");
          });
      }
    });
  };

  if (!draw) return null;

  return (
    <AdminLayout title={draw.title || "Çekiliş Ekle"}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/draws")}
        sx={{ mr: 3 }}
      >
        Çekilişlere Dön
      </Button>
      {id && (
        <>
          <Button
            variant="contained"
            startIcon={<PeopleIcon />}
            color="info"
            onClick={() => navigate(`/draw-participants/${id}`)}
            sx={{ mr: 2 }}
          >
            Katılımcılar
          </Button>
          <Button
            variant="contained"
            startIcon={<AnalyticsIcon />}
            color="success"
            onClick={() => navigate(`/statistics/${id}`)}
            sx={{ mr: 2 }}
          >
            İstatistikler
          </Button>
          <Button
            variant="contained"
            startIcon={<VideogameAssetIcon />}
            color="warning"
            onClick={() => window.open(`/game/${id}`, "_blank")}
            sx={{ mr: 2 }}
          >
            Çekiliş Ekranı
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={handleDeleteGameData}
          >
            Çekiliş ve Kazanan Verilerini Sil
          </Button>
        </>
      )}
      {draw.id ? (
        <Alert sx={{ my: 3 }} severity="warning">
          Çekilişinizi oluşturdunuz. Yukarıdaki <strong>Katılımcılar</strong>{" "}
          butonuyla katılımcıları toplu veya tek tek ekleyebilirsiniz.{" "}<br />
          Artık <strong>Ödül Alanları</strong> sekmesindeki <strong>Ödül Sayısı</strong>{" "}
          oluşturulmuş çekiliş içinde değiştirilemez.
        </Alert>
      ) : (
        <Alert sx={{ my: 3 }} severity="info">
          Temel çekiliş bilgilerinizi girdikten sonra kaydetmeden önce lütfen
          yan sekmelerdeki bilgileri de düzenleyiniz. Dilerseniz{" "}
          <strong>Ödül Sayısı</strong> hariç bilgilerinizi sonradan da
          düzenleyebilirsiniz. <strong>Ödül Alanları</strong>nda yer alan{" "}
          <strong>Ödül Sayısı</strong> çekiliş oluşturulduktan sonra
          değiştirilemez. Çekilişlerinizi oluşturduktan sonra ödül
          istatistiklerini inceleyebileceğiniz <strong>İstatistikler</strong>{" "}
          butonu hemen yukarıda yer alacaktır.
        </Alert>
      )}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          label="Çekiliş Alanları"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Ekran Tasarım Alanları"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
        <Tab
          label="Ödül Alanları"
          id="simple-tab-2"
          aria-controls="simple-tabpanel-2"
        />
      </Tabs>
      <Divider sx={{ mb: 5 }} />
      <form onSubmit={handleSubmit}>
        <div
          role="tabpanel"
          hidden={tabValue !== 0}
          id="simple-tabpanel-0"
          aria-labelledby="simple-tab-0"
        >
          <Grid container spacing={4}>
            <AdminField
              label="Çekiliş Başlığı"
              value={titleInput}
              onChange={(value) => setTitleInput(value)}
              error={!!errors.titleInput}
              errorMessage={errors.titleInput}
            />
            <AdminField
              label="Başlangıç Tarihi"
              type="date"
              value={draw.startDate}
              onChange={(newValue) =>
                handleFieldChange("startDate", newValue || dayjs())
              }
              error={!!errors.startDate}
              errorMessage={errors.startDate}
            />
            <AdminField
              label="Bitiş Tarihi"
              type="date"
              value={draw.endDate}
              onChange={(newValue) =>
                handleFieldChange("endDate", newValue || dayjs())
              }
              error={!!errors.endDate}
              errorMessage={errors.endDate}
            />
            <AdminField
              label="Yayında mı?"
              type="switch"
              value={draw.isActive}
              onChange={(value) =>
                handleFieldChange("isActive", value)
              }
            />
          </Grid>

          <Divider sx={{ mb: 5 }} />
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
            Ek Bilgi Alanları
          </Typography>
              
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Açıklama"
              value={draw.description}
              helperText="Etkinliğin açıklama metnini yazınız"
              onChange={(value) =>
                handleFieldChange("description", value)
              }
            />
            <AdminField
              label="Firma Adı"
              value={draw.companyName}
              helperText="Etkinliğin yapılacağı firmanın adını yazınız"
              onChange={(value) =>
                handleFieldChange("companyName", value)
              }
            />
            <AdminField
              label="Etkinlik Yeri"
              value={draw.venue}
              helperText="Etkinliğin yapılacağı yeri yazınız"
              onChange={(value) => handleFieldChange("venue", value)}
            />
          </Grid>

          <Divider sx={{ mb: 5 }} />
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
            Özelleştirme
          </Typography>

          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Özel CSS"
              value={draw.customCSS}
              helperText="Eğer çekiliş ekranında sadece bu kampanya için geçerli olacak görselleştirme uygulayacaksanız bu alan içinde özel CSS kullanınız."
              onChange={(value) => handleFieldChange("customCSS", value)}
              multiline
              rows={10}
            />
          </Grid>
        </div>
        {draw.drawDesign && (
          <>
            <div
              role="tabpanel"
              hidden={tabValue !== 1}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Ekran Tasarım Alanları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Çekiliş Ekran Tipi"
                  type="radio"
                  value={draw.drawDesign.screenType}
                  disabled={id != null}
                  helperText={
                    id != null
                      ? "Çekiliş ekran tipi kampanya oluşturulduktan sonra değiştirilemez."
                      : "Çekiliş ekran tasarımını klasik tablo çekilişi olarak veya farklı platform tasarımlı tasarım tipleri arasından seçiniz."
                  }
                  onChange={(value) => handleScreenTypeChange(parseInt(value))}
                  selectOptions={[
                    { label: "Klasik", value: 1 },
                    { label: "Üç Platformlu", value: 2 },
                    { label: "Desenli Platformlu", value: 3 },
                    { label: "Standart Platformlu", value: 4 },
                  ]}
                />
                <AdminField
                  label="Çekiliş Süresi"
                  value={draw.drawDuration}
                  type="number"
                  onChange={(value) => handleFieldChange("drawDuration", value)}
                  helperText="Butona basıldıktan sonra çekilişin kaç saniyede sonuçlanacağını rakam olarak yazınız"
                />
                <AdminField
                  label="Ekran Logosu"
                  value={draw.drawDesign.logo}
                  onChange={(value) => handleDrawDesignChange("logo", value)}
                  helperText="Çekiliş ekranında yer alacak firma logosunu ekleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("logo")}
                />
                <AdminField
                  label="Ekran Başlığı"
                  value={draw.drawDesign.pageTitle}
                  onChange={(value) => handleDrawDesignChange("pageTitle", value)}
                  helperText="Çekiliş ekranında yer alacak başlığı yazınız"
                />
                <AdminField
                  label="Ekran Arkaplan Görseli"
                  value={draw.drawDesign.screenBg}
                  onChange={(value) => handleDrawDesignChange("screenBg", value)}
                  helperText="Çekiliş ekranı arkaplan desen görselini yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("screenBg")}
                />
                <AdminField
                  label="Ekran Ana Rengi"
                  type="color"
                  inline
                  value={draw.drawDesign.mainColor}
                  onChange={(value) => handleDrawDesignChange("mainColor", value)}
                  helperText="Çekiliş ekranında kullanılacak ana rengi seçiniz"
                />
                <AdminField
                  label="Ekran İkincil Rengi"
                  type="color"
                  inline                  
                  value={draw.drawDesign.secondaryColor}
                  onChange={(value) => handleDrawDesignChange("secondaryColor", value)}
                  helperText="Çekiliş ekranında kullanılacak ikincil rengi seçiniz"
                />
                <AdminField
                  label="Ekran Üçüncü Rengi"
                  type="color"
                  inline
                  inlineFix={2}
                  value={draw.drawDesign.thirdColor}
                  onChange={(value) => handleDrawDesignChange("thirdColor", value)}
                  helperText="Çekiliş ekranında kullanılacak üçüncü rengi seçiniz"
                />
                <AdminField
                  label="Ödül Popup Arkaplan"
                  value={draw.drawDesign.modalBg}
                  onChange={(value) => handleDrawDesignChange("modalBg", value)}
                  helperText="Ödül popupına özel arkaplan kullanmak için buradan ekleyiniz. Gif formatında hareketli görsel kullanabilirsiniz."
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("modalBg")}
                />
                <AdminField
                  label="Yazı Ana Rengi"
                  type="color"
                  inline
                  value={draw.drawDesign.mainTextColor}
                  onChange={(value) => handleDrawDesignChange("mainTextColor", value)}
                  helperText="Yazı fontlarında kullanılacak ana rengi seçiniz"
                />
                <AdminField
                  label="Ödül Popup Yazı Rengi"
                  inline
                  type="color"
                  value={draw.drawDesign.modalTextColor}
                  onChange={(value) => handleDrawDesignChange("modalTextColor", value)}
                  helperText="Ödül popupının yazı rengini seçiniz"
                />
                <AdminField
                  label="Tablo Kenar Rengi"
                  inline
                  inlineFix={2}
                  type="color"
                  value={draw.drawDesign.tableBorderColor}
                  onChange={(value) => handleDrawDesignChange("tableBorderColor", value)}
                  helperText="Klasik EKran Tipindeki tablonun kenar rengini seçiniz"
                />
                <AdminField
                  label="Ödül Platform Görseli"
                  value={draw.drawDesign.platformImage}
                  onChange={(value) => handleDrawDesignChange("platformImage", value)}
                  helperText="Çekiliş ekranındaki ödül platformu için özel görsel kullanmak istiyorsanız buradan ekleyiniz. Çekiliş ekran tipi dekoratif ise ona uygun bir görsel ekleyiniz."
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("platformImage")}
                />
                <AdminField
                  label="Kazanan İsim Görseli"
                  value={draw.drawDesign.winnerBg}
                  onChange={(value) => handleDrawDesignChange("winnerBg", value)}
                  helperText="Kazanan isminin arkasında yer alacak görseli değiştirmek istiyorsanız buradan ekleyiniz."
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("winnerBg")}
                />
                <AdminField
                  label="Ana Yazı Tipi"
                  type="select"
                  value={draw.drawDesign.mainFont}
                  onChange={(value) =>
                    handleDrawDesignChange("mainFont", value)
                  }
                  helperText="Çekiliş ekranında kullanılacak ana yazı tipini seçiniz"
                  selectOptions={[
                    { label: "Open Sans", value: "Open Sans" },
                    { label: "Poppins", value: "Poppins" },
                    { label: "Roboto", value: "Roboto" },
                    { label: "Yuji Mai", value: "Yuji Mai" }
                  ]}
                />
                <AdminField
                  label="İkincil Yazı Tipi"
                  type="select"
                  value={draw.drawDesign.secondaryFont}
                  onChange={(value) =>
                    handleDrawDesignChange("secondaryFont", value)
                  }
                  helperText="Çekiliş ekranında kullanılacak ikincil yazı tipini seçiniz"
                  selectOptions={[
                    { label: "Open Sans", value: "Open Sans" },
                    { label: "Poppins", value: "Poppins" },
                    { label: "Roboto", value: "Roboto" },
                    { label: "Yuji Mai", value: "Yuji Mai" }
                  ]}
                />
              </Grid>
            </div>
          </>
        )}
        <div
          role="tabpanel"
          hidden={tabValue !== 2}
          id="simple-tabpanel-2"
          aria-labelledby="simple-tab-2"
        >
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Ödül Alanları
              </Typography>
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Ödül Sayısı"
              type="select"
              value={draw.drawCount}
              onChange={(value) =>
                handlePrizeAmountChange({
                  target: { value: value.toString() },
                } as React.ChangeEvent<HTMLInputElement>)
              }
              disabled={draw.drawDesign.screenType === 2 || id != null}
              helperText={
                id != null
                  ? "Ödül sayısı kampanya oluşturulduktan sonra değiştirilemez."
                  : (draw.drawDesign.screenType === 2 ? "Üç platformlu ekran tipinin ödül sayısı sadece 3 olabilir"
                    : "Çekilişte kaç ödül alanı (platform) olacağını seçiniz")
              }
              selectOptions={[
                { label: "3", value: 3 },
                { label: "4", value: 4 },
                { label: "5", value: 5 },
                { label: "6", value: 6 },
                { label: "8", value: 8 },
                { label: "10", value: draw.drawDesign.screenType !== 3 ? 10 : undefined },
                { label: "12", value: draw.drawDesign.screenType !== 3 ? 12 : undefined },
              ].filter((option) => option.value !== undefined)}
            />

            <Grid size={2}>
              <Typography variant="subtitle2">Ödüller</Typography>
            </Grid>
            <Grid size={10}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                {Array.isArray(draw.prizes) &&
                  draw.prizes.map((prize: any, index: any) => (
                    <div key={prize.id || index}>
                      <h4>Ödül {index + 1}</h4>
                      <Grid container spacing={3}>
                        <AdminField
                          type="disabled"
                          value={prize.listOrder}
                          fullWidth={false}
                          inline
                          onChange={(value) =>
                            handlePrizeChange(index, "listOrder", value)
                          }
                        />
                        <Grid>
                          <IconButton
                            aria-label="move prize up"
                            onClick={() => movePrize(index, "up")}
                            disabled={index === 0}
                          >
                            <ArrowUpwardIcon />
                          </IconButton>
                          <IconButton
                            aria-label="move prize down"
                            onClick={() => movePrize(index, "down")}
                            disabled={
                              index === draw.prizes.length - 1
                            }
                          >
                            <ArrowDownwardIcon />
                          </IconButton>
                        </Grid>
                        <AdminField
                          label="Ödül Adı"
                          value={prize.prizeValue}
                          fullWidth={false}
                          inline
                          onChange={(value) =>
                            handlePrizeChange(index, "prizeValue", value)
                          }
                          error={!!errors.prizes}
                          errorMessage={errors.prizes}
                        />
                        <AdminField
                          label="Ödül Görseli"
                          value={prize.platformImage}
                          inline
                          onChange={(value) =>
                            handlePrizeChange(index, "platformImage", value)
                          }
                          helperText="Ödülün platform görselini ekleyiniz"
                          type="button"
                          buttonText="Galeri'den Seç"
                          onClick={() =>
                            openImagePicker(`prize-${index}-platformImage`)
                          }
                        />
                        <AdminField
                          label="Popup Görseli"
                          value={prize.prizeImage}
                          inline
                          onChange={(value) =>
                            handlePrizeChange(index, "prizeImage", value)
                          }
                          helperText="Ödülün popup görselini ekleyiniz"
                          type="button"
                          buttonText="Galeri'den Seç"
                          onClick={() =>
                            openImagePicker(`prize-${index}-prizeImage`)
                          }
                        />
                        
                      </Grid>
                      <Divider sx={{ my: 3 }} />
                    </div>
                  ))}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>
        <Divider />
        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ my: 4 }}
        >
          Kaydet
        </Button>
      </form>
      <ImagePickerModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSelect={handleImageSelect}
      />
    </AdminLayout>
  );
}