import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Draw } from "../models/draw";
import agent from "../api/agent";
import { v4 as uuid } from "uuid";
import CenteredCard from "../layout/CenteredCard";
import { env } from "../utils/env";
import { Button } from "@mui/material";
import { Participant } from "../models/participant";
import Swal from "sweetalert2";
import { Game } from "../models/game";
import { toast } from "react-toastify";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export default function GameBoard() {
  const { id } = useParams<{ id: string }>();
  const [draw, setDraw] = useState<Draw | null>(null);
  const [participants, setParticipants] = useState<Participant[] | null>(null);
  const [winners, setWinners] = useState<string[]>([]);
  const [currentRow, setCurrentRow] = useState<number>(0);
  const [isSelecting, setIsSelecting] = useState<boolean>(false);
  const [currentWinner, setCurrentWinner] = useState<string>("");

  const remainingParticipants = participants?.filter(
    (participant) => !participant.hasWon
  ) || [];

  let backgroundImage = "";
  if (draw?.drawDesign.screenBg) {
    backgroundImage = `url(${env + draw.drawDesign.screenBg})`;
  } else {
    backgroundImage = `url(${process.env.PUBLIC_URL}/assets/draw-bg-2.png)`;
  }

  const fetchDraw = async () => {
    if (id) {
      try {
        const fetchedDraw = await agent.Draws.details(id);
        fetchedDraw.prizes.sort((a, b) => a.listOrder - b.listOrder);
        setDraw(fetchedDraw);

        const fetchedParticiPants = await agent.Draws.listParticipants(fetchedDraw.id);
        setParticipants(fetchedParticiPants);

        const playedGames = await agent.Games.listOfDraw(fetchedDraw.id);

        const updatedWinners = new Array(fetchedDraw.prizes.length).fill("");
        playedGames.forEach((game) => {
          const prizeIndex = fetchedDraw.prizes.findIndex(
            (prize) => prize.prizeValue === game.prize
          );
          if (prizeIndex !== -1) {
            updatedWinners[prizeIndex] = game.name;
          }
        });


        setWinners(updatedWinners);
        setCurrentRow(playedGames.length);
      } catch (error) {
        console.error("Failed to fetch draw:", error);
      }
    } else {
      console.error("No draw id provided");
    }
  };
  
  useEffect(() => {
    fetchDraw();

  }, [id]);

  const pickWinner = async (rowIndex: number) => {
    if (!draw || !participants || isSelecting || currentRow >= draw.prizes.length) return;

    setIsSelecting(true);

    const prize = draw!.prizes[rowIndex];
    let randomIndex: number;
    let winner: Participant | null = null;

    // Temporary animation to show random names
    const animationInterval = setInterval(() => {
      randomIndex = Math.floor(Math.random() * remainingParticipants.length);
      winner = remainingParticipants[randomIndex];
      setCurrentWinner(winner.name || "");
    }, 50);

    // Finalize winner after 5 seconds
    await new Promise((resolve) => setTimeout(resolve, draw.drawDuration * 1000));
    clearInterval(animationInterval);


    randomIndex = Math.floor(Math.random() * remainingParticipants.length);
    winner = remainingParticipants[randomIndex];

    setCurrentWinner("");

    if (!winner) {
      setIsSelecting(false);
      return;
    }

    const game: Game = {
      id: uuid(),
      prize: prize.prizeValue,
      drawId: draw!.id,
      participantId: winner.id
    };

    try {
      await agent.Games.create(game);

      setParticipants((prev) => {
        const updatedParticipants = (prev ?? []).map((participant) =>
          participant.id === winner!.id
            ? { ...participant, hasWon: true }
            : participant
        );
        return updatedParticipants;
      });

      setWinners((prev) => {
        const updatedWinners = [...prev];
        updatedWinners[rowIndex] = winner!.name;
        return updatedWinners;
      });

      setCurrentRow(currentRow + 1);

      Swal.fire({
        title: prize.prizeValue,
        html: `<div class="swal-inner-text"><span>Tebrikler!</span><span>${winner!.name}</span></div>`,
        imageUrl: env + prize.prizeImage || undefined,
        imageWidth: 200,
        color: draw.drawDesign.modalTextColor || "#fff",
        showCancelButton: false,
        showConfirmButton: false,
        customClass: {
          popup: 'swal-container swal-bg',
          icon: 'd-none',
          title: prize.prizeImage ? 'd-none' : 'swal-prize-value',
          image: prize.prizeImage ? 'swal-image' : 'd-none',
        },
        background: "transparent",
        didOpen: () => {
          document.addEventListener('click', () => {
            Swal.close();
          });
        },
      });

    } catch (error) {
      console.error("Failed to create game:", error);
      toast.error("Kazanan bilgisi kaydedilemedi.");
    } finally {
      setIsSelecting(false);
    }
  };

  if (!draw || !participants) {
    return <CenteredCard>Yükleniyor...</CenteredCard>;
  }

  const participantsMoreThanPrizes = participants.length >= draw.prizes.length;
  if (!participantsMoreThanPrizes) {
    Swal.fire({
      title: "Yeterli sayıda katılımcı yok",
      text: "Çekilişin başlayabilmesi için eklenmiş katılımcı sayısı ödül sayısından fazla olmalıdır.",
      icon: "info",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showCloseButton: false,
      showConfirmButton: false,
    });
  }

  let gameBoardClass = "game-board";
  if (currentRow >= draw.prizes.length || isSelecting) {
    gameBoardClass = "game-board title-bg";
  }

  const calculateRows = (screenType: number, prizes: any) => {
    const totalPlatforms = prizes.length;

    if (screenType === 3 && totalPlatforms <= 4) {
      return [prizes];
    }
    if (screenType === 4 && totalPlatforms <= 3) {
      return [prizes];
    }

    const platformsPerRow = Math.ceil(totalPlatforms / 2);
    const rows = [];
  
    rows.push(prizes.slice(0, platformsPerRow)); 
    rows.push(prizes.slice(platformsPerRow));
  
    return rows;
  };

  const decoratedRows = calculateRows(3, draw.prizes);
  const standardRows = calculateRows(4, draw.prizes);

  const screenType = (() => {
    switch (draw.drawDesign.screenType) {
      case 1:
        return (
          <table>
            {draw.prizes.map((prize, index) => (
              <tr
                key={index}
                className={
                  index === currentRow
                    ? "active-row"
                    : winners[index]
                    ? "winner-row"
                    : ""
                }
              >
                <td>{winners[index] || ""}</td>
                <td>{prize.prizeValue}</td>
              </tr>
            ))}
          </table>
        );
      
      case 2:
        return (
          <div className="three-level-platform">
            <div className="platforms">
              <div className="single third">
                <div
                  className={
                    currentRow === 0
                      ? "platform-content active-platform"
                      : winners[0]
                      ? "platform-content winner-platform"
                      : "platform-content"
                  }
                >
                  <img src={draw.prizes[0].platformImage ? env + draw.prizes[0].platformImage : `${process.env.PUBLIC_URL}/assets/gift-box.png`} alt={draw.prizes[0].prizeValue} />
                  <span className="winner-name">{winners[0] || ""}</span>
                </div>
              </div>
              <div className="single second">
                <div
                  className={
                    currentRow === 1
                      ? "platform-content active-platform"
                      : winners[1]
                      ? "platform-content winner-platform"
                      : "platform-content"
                  }
                >
                  <img src={draw.prizes[1].platformImage ? env + draw.prizes[1].platformImage : `${process.env.PUBLIC_URL}/assets/gift-box.png`} alt={draw.prizes[1].prizeValue} />
                  <span className="winner-name">{winners[1] || ""}</span>
                </div>
              </div>
              <div className="single first">
                <div
                  className={
                    currentRow === 2
                      ? "platform-content active-platform"
                      : winners[2]
                      ? "platform-content winner-platform"
                      : "platform-content"
                  }
                >
                  <img src={draw.prizes[2].platformImage ? env + draw.prizes[2].platformImage : `${process.env.PUBLIC_URL}/assets/gift-box.png`} alt={draw.prizes[2].prizeValue} />
                  <span className="winner-name">{winners[2] || ""}</span>
                </div>
              </div>
            </div>
          </div>
        );

      case 3: case 4:
        const rowMapType = draw.drawDesign.screenType === 3 ? decoratedRows : standardRows;
        return (
          <div className={`platforms-container ${draw.drawDesign.screenType === 3 && "decorated"}`}>
            {rowMapType.map((row, rowIndex) => (
              <div
                key={rowIndex}
                className="platforms"
                style={{
                  justifyContent: row.length < rowMapType[0].length ? "center" : "space-around",
                }}
              >
                {row.map((prize: any, platformIndex: number) => {
                  const prizeIndex = rowIndex * rowMapType[0].length + platformIndex;

                  return (
                    <div
                      key={prizeIndex}
                      className={
                        prizeIndex === currentRow
                          ? "platform active-platform"
                          : winners[prizeIndex]
                          ? "platform winner-platform"
                          : "platform"
                      }
                    >
                      <div className="platform-content">
                        <img src={prize.platformImage ? env + prize.platformImage : `${process.env.PUBLIC_URL}/assets/gift-box.png`} alt={prize.prizeValue} />
                        <span className="winner-name">{winners[prizeIndex] || ""}</span>
                        {!prize.platformImage && <span className="prize-value">{prize.prizeValue}</span>}
                      </div>
                    </div>
                  );
                })}
              </div>
            ))}
          </div>
        );
      
      default: // Fallback for unknown screen types
        return <h3>Ekran tipi tanımlı değil</h3>;
    }
  });

  return (
    <>
      <style>
        {`
          body {
            overflow: hidden;
            font-family: '${draw.drawDesign.mainFont}', sans-serif;
          }
          .game-screen .start-button-classic {
            color: ${draw.drawDesign.mainColor};
            border-color: ${draw.drawDesign.mainColor};
            font-family: '${draw.drawDesign.secondaryFont}', sans-serif;
          }
          .game-screen .start-button-classic:hover {
            color: ${draw.drawDesign.secondaryColor};
            border-color: ${draw.drawDesign.secondaryColor};
          }
          ${draw.drawDesign.pageTitle && ".game-screen .screen-logo {margin-bottom: 0}"}
          .game-screen .page-title {
            color: ${draw.drawDesign.mainTextColor}
          }
          .game-control {
            background-image : url(${draw.drawDesign.winnerBg ? env + draw.drawDesign.winnerBg : `${process.env.PUBLIC_URL}/assets/title-bg.png`});
          }
          .game-board table {
            color: #000;
          }
          .game-board table, .game-board table td, .game-board table tr td:first-child  {
            border-color: ${draw.drawDesign.tableBorderColor};
          }
          .active-row {
            color: ${draw.drawDesign.secondaryColor};
          }
          .winner-row {
            color: ${draw.drawDesign.mainColor};
            font-weight: bold;
          }
          ${draw.drawCount <= 6 ? ".platforms-container {width: 60%;}" : ""}
          .platforms-container .platforms .platform-content {
            background-image : url(${draw.drawDesign.platformImage ? env + draw.drawDesign.platformImage : `${process.env.PUBLIC_URL}/assets/platform.png`});
          }
          .three-level-platform .platforms {
            background-image : url(${draw.drawDesign.platformImage ? env + draw.drawDesign.platformImage : `${process.env.PUBLIC_URL}/assets/three-platform.png`});
          }
          .platforms-container.decorated .platforms .platform-content {
            background-image : url(${draw.drawDesign.platformImage ? env + draw.drawDesign.platformImage : `${process.env.PUBLIC_URL}/assets/decorated-platform.png`});
          }
            .swal-bg::before {
            background-image: url(${draw.drawDesign.modalBg ? env + draw.drawDesign.modalBg : `${process.env.PUBLIC_URL}/assets/fireworks.webp`})
          }
        `}
        {draw.customCSS}
      </style>
      <main
        className="game-screen"
        style={{
          color: draw.drawDesign.mainColor || "000",
          backgroundImage: backgroundImage,
          backgroundSize: "cover, auto",
        }}
      >
        <div className={`game-board ${draw.drawDesign.screenType === 1 ? 'classic': ''}`}>
          {draw.drawDesign.logo && (
            <img
              className="screen-logo"
              src={env + draw.drawDesign.logo}
              alt={draw.title}
            ></img>
          )}
          {draw.drawDesign.pageTitle && (
            <h2 className="page-title">{draw.drawDesign.pageTitle}</h2>
          )}
          <div className={`game-control ${currentRow < draw.prizes.length ? (!isSelecting ? 'with-button': '') : ''}`}>
            {!isSelecting ? (
              currentRow >= draw.prizes.length ? (
                <h3 className="draw-complete"><CheckCircleIcon color="success" fontSize="large" /> Çekiliş Tamamlandı</h3>
              ) : (
                <Button
                  className="start-button-classic"
                  variant="contained"
                  type="button"
                  onClick={() => pickWinner(currentRow)}
                  disabled={currentRow >= draw.prizes.length || !participantsMoreThanPrizes}
                >
                  Başlat
                </Button>
              )
            ) : (
              <h3 className="temporary-name">{currentWinner}</h3>
            )}
          </div>
          {screenType()}
        </div>
      </main>
    </>
  );
}