import dayjs from "dayjs";
import { Draw } from "../models/draw";

export const initializeNewDraw = (): Draw => {
  return {
    id: "",
    title: "",
    startDate: dayjs(),
    endDate: dayjs(),
    isActive: true,
    companyName: "",
    venue: "",
    description: "",
    listOrder: 0,
    customCSS: "",
    drawCount: 5,
    drawDuration: 5,
    drawDesign: {
      id: "",
      screenType: 1,
      logo: "",
      pageTitle: "",
      screenBg: "",
      mainColor: "#000",
      secondaryColor: "#000",
      thirdColor: "#fff",
      modalBg: "",
      mainTextColor: "#000",
      modalTextColor: "#fff",
      platformImage: "",
      winnerBg: "",
      mainFont: "Open Sans",
      secondaryFont: "Open Sans",
      tableBorderColor: "#fff",          
      drawId: ""
    },
    prizes: Array(5).fill({
      id: "",
      prizeValue: "",
      prizeImage: "",
      platformImage: "",
      listOrder: null,
    })
  };
};

export const initializeDrawData = (drawData: Draw): Draw => {
  return {
    ...drawData,
    startDate: dayjs(drawData.startDate),
    endDate: dayjs(drawData.endDate),
    drawDesign: {
      ...drawData.drawDesign,
      screenType: drawData.drawDesign.screenType || 1
    },
  };
};