import React from 'react';
import ReactDOM from 'react-dom/client';
import { store, StoreContext } from './stores/store';
import { router } from './router/Routes';
import { RouterProvider } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css'
import './layout/styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StoreContext.Provider value={store}>
  <RouterProvider router={router} />
</StoreContext.Provider>
);
