import { useEffect, useState } from "react";
import { Participant } from "../models/participant";
import { Draw } from "../models/draw";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { v4 as uuid } from "uuid";
import * as XLSX from "xlsx";
import agent from "../api/agent";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { Alert, Box, Button, Chip, FormControlLabel, IconButton, Modal, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadIcon from '@mui/icons-material/Upload';
import DownloadIcon from "@mui/icons-material/Download";
import AdminLayout from "../layout/AdminLayout";

interface ExcelRow {
  Name: string;
  Gender?: string;
  Email?: string;
  Phone?: string;
  Company?: string;
}

export default function DrawParticipants() {
  const { id } = useParams<{ id: string }>();
  const [participants, setParticipants] = useState<Participant[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  
  const initialFormValues: any = {
    name: "",
    gender: 0,
    email: "",
    phone: "",
    company: "",
  };
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const [errors, setErrors] = useState<any>({});
  const [fileInputKey, setFileInputKey] = useState(0);

  const fetchParticipants = async () => {
    setLoading(true);
    if (id) {
      try {
        const response = await agent.Draws.listParticipants(id);
        const fetchedParticipants = response;
        setParticipants(fetchedParticipants);
        setLoading(false);        
      } catch (error) {
        setLoading(false);
        toast.error('Katılımcılar getirilemedi.');
      }
    }
  };

  useEffect(() => {
    fetchParticipants();
  }, [id]);

  useEffect(() => {
    if (location.state?.reload) {
      fetchParticipants();
    }
  }, [location.state]);

  const handleDeleteParticipant = (id: string) => {
    Swal.fire({
        title: 'Silmek istediğinizden emin misiniz?',
        text: 'Silme işlemini geri alamazsınız!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Tamam',
        cancelButtonText: 'İptal',
    }).then((result) => {
        if (result.isConfirmed) {
          agent.Draws.deleteParticipant(id).then(() => {
            console.log(id);
            setParticipants([...participants.filter(x => x.id !== id)]);
            toast.success('Katılımcı silindi!');
          }).catch((error) => {
              toast.error('Çekiliş silinirken bir hata oluştu.');
          });
        }
    });
  };

  const handleDeleteAllParticipants = () => {
    Swal.fire({
      title: "Silmek istediğinizden emin misiniz?",
      text: "Bu çekiliş için kaydedilmiş tüm katılımcı verileri silinecektir. Silme işlemini geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Tamam",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (id && result.isConfirmed) {
        agent.Draws.deleteAllParticipants(id)
          .then(() => {
            setParticipants([]);
            toast.success("Tüm katılımcı verileri silindi!");
          })
          .catch((error) => {
            toast.error("Veriler silinirken bir hata oluştu.");
          });
      }
    });
  };

  const handleFileUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target?.result as ArrayBuffer;
      const workbook = XLSX.read(new Uint8Array(arrayBuffer), { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];

      const rows: (string | undefined)[][] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
      });
  
      // Transform data into participants
      const participants = rows.filter((row) => row[0]).map((row) => ({
        id: uuid(),
        name: row[0] || "",
        email: row[1] || "",
        phone: row[2]?.toString() || "",
        company: row[3] || "",
        gender:
          row[4]?.toLowerCase() === "erkek"
          ? 1
          : row[4]?.toLowerCase() === "kadın"
          ? 2
          : 0,
        hasWon: false,
        drawId: id || ""
      }));
  
      if (participants.length === 0) {
        toast.error(
          "Excel dosyasında Katılımcı Adı alanı olan ilk kolon boş bırakılamaz!"
        );
      } else {
        uploadParticipants(participants);
      }
      setFileInputKey(prevKey => prevKey + 1);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadParticipants = async (participants: Participant[]) => {
    try {
      await agent.Draws.bulkParticipants(participants);
      setParticipants((prev) => [...prev, ...participants]);
      toast.success("Katılımcılar başarıyla eklendi");
    } catch (error) {
      toast.error("katılımcılar eklenirken bir hata oluştu");
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: name === 'gender' ? parseInt(value) : value
    });
  };

  const validateForm = () => {
    let tempErrors: any = {};

    if (!formValues.name) tempErrors.name = "Katılımcı Adı zorunludur";

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>)  => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    const participantData = {
      id: uuid(),
      name: formValues.name,
      gender: formValues.gender,
      email: formValues.email,
      phone: formValues.phone,
      company: formValues.company,
      hasWon: false,
      drawId: id || ""
    }

    try {
      await agent.Draws.createParticipant(participantData);
      setParticipants([...participants, participantData]);
      toast.success("Katılımcı başarıyla eklendi");
    } catch (error) {
      toast.error("katılımcı eklenirken bir hata oluştu");
    }

    handleClose();
  };

  const handleClose = () => {    
    setOpenModal(false);
    setFormValues({
      name: "",
      email: "",
      phone: "",
      company: "",
      gender: 0,
    });
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "İsim",
      width: 180,
    },    
    {
      field: "email",
      headerName: "E-Posta",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Telefon",
      width: 140,
    },
    {
      field: "company",
      headerName: "Firma",
      width: 160,
    },
    {
      field: "gender",
      headerName: "Cinsiyet",
      width: 100,
      renderCell: (params: any) => {
        if (params.value === 1) {
          return (<Chip label="Erkek" />);
        } else if (params.value === 2) {
          return (<Chip label="Kadın" />);
        }
        return "";
      }
    },
    {
      field: "hasWon",
      headerName: "Kazanan Mı?",
      width: 140,
      renderCell: (params) => (
        params.value ? (
          <Chip label="Kazandı" color="success" />
        ) : ""
      ),
    },
    {
      field: "actions",
      headerName: "Eylemler",
      width: 360,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={() => handleDeleteParticipant(params.row.id)}
          >
            Sil
          </Button>
      </>
      ),
    },
  ];

  return (
    <>
      <AdminLayout title="Katılımcılar">
        <Grid
          container
          spacing={2}
          marginBottom={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid size={{xs: 6, sm: 6, md: 6}}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate(`/draws/${id}`)}
              sx={{ mb: 2, mr: 3 }}
            >
              Çekilişe Dön
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenModal(true)}
              sx={{ mb: 2, mr: 3 }}
            >
              Katılımcı Ekle
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={handleDeleteAllParticipants}
              sx={{ mb: 2 }}
            >
              Tüm Katılımcıları Sil
            </Button>
          </Grid>
          <Grid size={{xs: 6, sm: 6, md: 4}}>
            <input
              key={fileInputKey}
              type="file"
              accept=".xlsx"
              style={{ display: "none" }}
              id="file-upload"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) handleFileUpload(file);
              }}
            />
            <label htmlFor="file-upload">
              <Button
                variant="contained"
                startIcon={<UploadIcon />}
                color="success"
                component="span"
              >
                Excel Dosya Gönder
              </Button>
            </label>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          marginBottom={2}
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Grid size={{xs: 9, sm: 9, md: 8}}>
            <Alert sx={{ my: 3 }} severity="info">
              Toplu katılımcı eklemek için Sağ üstteki <strong>Excel Dosya Gönder</strong>{" "}
              butonunu kullanabilirsiniz. Sadece <strong>xlsx</strong> formatında{" "}
              dosya ekleyebilirsiniz.
            </Alert>
          </Grid>
          <Grid size={{xs: 3, sm: 3, md: 4}}>
            <Button
              variant="contained"
              size="small"
              color="info"
              onClick={() => setOpenInfoModal(true)}
              sx={{ mb: 2, mr: 3 }}
            >
              Dosya Yükleme Kılavuzu
            </Button>
          </Grid>
        </Grid>
        
        <DataGrid
          rows={participants}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 20 },
            },
          }}
          pageSizeOptions={[20, 50, 100]}
          checkboxSelection
        />
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className="game-modal modal"
        >
          <Box
            sx={{
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <p>Katılımcı Ekle</p>
            <form className="login-form" onSubmit={handleSubmit}>
              <TextField
                label="Katılımcı Adı"
                name="name"
                type="text"
                fullWidth
                variant="outlined"
                value={formValues.name}
                onChange={handleInputChange}
                required
                helperText={errors.name}
                error={!!errors.name}
              />
              <TextField
                label="E-Posta"
                name="email"
                type="email"
                fullWidth
                variant="outlined"
                value={formValues.email}
                onChange={handleInputChange}
              />
              <TextField
                label="Telefon"
                name="phone"
                type="text"
                fullWidth
                variant="outlined"
                value={formValues.phone}
                onChange={handleInputChange}
              />
              <TextField
                label="Firma Adı"
                name="company"
                type="text"
                fullWidth
                variant="outlined"
                value={formValues.company}
                onChange={handleInputChange}
              />
              <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
                Cinsiyet
              </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="gender"
                value={formValues.gender || 0}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value={1}
                  control={<Radio />}
                  label="Erkek"
                />
                <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label="Kadın"
                />
              </RadioGroup>
              <Button
                variant="contained"
                type="submit"
                sx={{ my: 4, backgroundColor: "#FAB406" }}
              >
                Gönder
              </Button>
            </form>
          </Box>
        </Modal>
        <Modal
          open={openInfoModal}
          onClose={() => setOpenInfoModal(false)}
          aria-labelledby="modal-info"
          aria-describedby="modal-file-upload-info"
          className="game-modal modal"
        >
          <Box
            sx={{
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              outline: "none",
            }}
          >
            <Alert sx={{ my: 3 }} severity="info">
              Toplu katılımcı eklemek için Sağ üstteki <strong>Excel Dosya Gönder</strong>{" "}
              butonunu kullanabilirsiniz. Sadece <strong>xlsx</strong> formatında{" "}
              dosya ekleyebilirsiniz. <br /><br />
              Excel dosyanızda katılımcı isimleri tek kolonda{" "}
              yer almak üzere İsim ve Soyad olarak ayrı kolonlara <strong>ayırmadan</strong>{" "}
              ilk kolona {"(A)"} eklenmelidir. <br />
              <strong>İkinci kolon {"(B)"}:</strong> E-Posta <br />
              <strong>Üçüncü kolon {"(C)"}:</strong> Telefon <br />
              <strong>Dördüncü kolon {"(D)"}:</strong> Firma <br />
              <strong>Beşinci kolon {"(E)"}:</strong> Cinsiyet {"(sadece erkek veya kadın olarak)"}<br /><br />
              
              Sadece ilk kolonu doldurmanız yeterlidir. Eğer diğer bilgilerin de yer almasını{" "}
              istiyorsanız youkarıdaki sıraya göre kolonları doldurabilirsiniz. İlk kolon dışında{" "}
              diğerlerini istediğiniz sırayla boş bırakabilirsiniz, bazılarında doldurmayabilir{" "}
              ya da tümünde doldurabilirsiniz. Ancak ilk kolonu boş olan satırlar kaydedilmeyecektir.<br /><br />

              Katılımcı ekleme formatı için <strong>Örnek Excel Dosyası</strong>nı aşağıdan indirebilirsiniz;<br /><br />

              <Button
                variant="contained"
                size="small"
                color="warning"
                component="a"
                href={`${process.env.PUBLIC_URL}/assets/ornek-toplu-katilimci-excel.xlsx`}
                download
                startIcon={<DownloadIcon />}
              >
                Örnek Dosya İndir
              </Button>
            </Alert>
          </Box>
        </Modal>
      </AdminLayout>
    </>
  );
}